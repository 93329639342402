@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  display: none;
}
@font-face {
  font-family: inter;
  src: url("./assets/fonts/Inter-VariableFont_opsz\,wght.ttf");
}
@font-face {
  font-family: poppinsMedium;
  src: url("./assets/fonts/Poppins-Medium.ttf");
}
@font-face {
  font-family: poppinsLight;
  src: url("./assets/fonts/Poppins-Light.ttf");
}
@font-face {
  font-family: poppinsExtraLight;
  src: url("./assets/fonts/Poppins-ExtraLight.ttf");
}
@font-face {
  font-family: poppinsSemiBold;
  src: url("./assets/fonts/Poppins-SemiBold.ttf");
}
.category-card {
  @apply p-4 border border-gray-300 rounded-lg shadow-md;
}

.category-card-image {
  @apply w-full h-32;
}

.category-card-content {
  @apply mt-2;
}

.category-card img {
  @apply w-full h-full object-cover;
}

.cutoff-text {
  --max-lines: 2;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--max-lines);
}
.cutoff-text-one {
  --max-lines: 1;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--max-lines);
}
.product-card {
  @apply p-4 border border-gray-300 rounded-lg shadow-md;
  width: 12rem; /* Adjust the width */
  height: 16rem; /* Adjust the height */
}

.product-card img {
  @apply w-full h-2/3 object-cover; /* Adjust height to fit card */
}

.product-card-content {
  @apply mt-2;
}

.cutoff-text {
  --max-lines: 2;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--max-lines);
}

.input-container {
  @apply w-[100%] flex items-stretch border-2 border-[#B7BBC170] bg-[#B7BBC11F] bg-opacity-[12%] rounded-md;
}
.input-text {
  @apply w-full h-full focus:outline-none flex-1 text-black/[0.5] bg-[#B7BBC11F] bg-opacity-[12%] rounded-md border-l-0 rounded-tl-none rounded-bl-none;
}

.diminishing-line {
  width: 100%; /* Adjust the width to fit your container */
  height: 1px; /* Adjust the height for the thickness of the line */
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.05) 0%,
    rgba(0, 0, 0, 0.3) 50.27%,
    rgba(0, 0, 0, 0.05) 100%
  );
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
}

.smooth-polygon {
  width: 300px;
  height: 300px;
  background-color: lightgreen;
  -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M 0,0 H 100 V 65 Q 50,100 0,60 Z" /></svg>')
    center / contain no-repeat;
  mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M 0,0 H 100 V 65 Q 50,100 0,60 Z" /></svg>')
    center / contain no-repeat;
}

.slide-up {
  animation: slideUp 0.4s ease-out forwards;
}

@keyframes slideUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}

.no-pointer {
  cursor: default !important;
}
